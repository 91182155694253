import { getImage } from "gatsby-plugin-image"
import { Shop } from "../../templates/dineshop"
import Mapplic from "../Mapplic/Mapplic"
import BackButton from "./BackButton"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"
interface Props {
  shop: Shop
}

const ShopDetail: React.FC<Props> = ({ shop }) => {
  console.log(shop)
  const backText = "Back"
  const hoursText = "Hours"
  const locationText = "Location"
  const title = shop.title
  const { shopHighlightText, shopDescription, locationTitle } = shop.shopPost
  const [text, setText] = useState({
    backText,
    hoursText,
    locationText,
    title,
    shopHighlightText,
    shopDescription,
    locationTitle,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        backText,
        hoursText,
        locationText,
        title,
        shopHighlightText,
        shopDescription,
        locationTitle,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          backText,
          hoursText,
          locationText,
          title,
          shopHighlightText,
          shopDescription,
          locationTitle,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`px-8 max-w-6xl mx-auto`]}>
      <BackButton />
      <h1
        dangerouslySetInnerHTML={{ __html: shop.title }}
        css={[
          tw`text-3xl sm:text-4xl font-extrabold mb-6 text-center sm:text-left`,
        ]}
      />
      <div
        css={tw`sm:hidden max-w-[12rem] max-h-[10rem] self-center md:pt-0 mx-auto mb-8`}
      >
        <Image
          src={
            shop?.shopPost?.shopThumbnailImage?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          publicURL={shop?.shopPost?.shopThumbnailImage?.localFile?.publicURL}
          alt=""
          role="presentation"
        />
      </div>
      <div css={[tw`flex flex-col sm:flex-row justify-evenly sm:gap-12`]}>
        <div css={[tw`flex-1`]}>
          <Image
            src={
              shop?.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            publicURL={shop?.featuredImage?.node?.localFile?.publicURL}
            alt=""
            role="presentation"
          />
        </div>
        <div css={[tw`flex flex-col flex-1 gap-6`]}>
          <div
            css={[
              tw`hidden sm:block max-w-[12rem] max-h-[10rem] self-center pt-8`,
            ]}
          >
            <Image
              src={
                shop?.shopPost?.shopThumbnailImage?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              publicURL={
                shop?.shopPost?.shopThumbnailImage?.localFile?.publicURL
              }
              alt=""
              role="presentation"
            />
          </div>
          <div>
            <h2
              css={[tw`text-xl mt-8 sm:mt-0`]}
              dangerouslySetInnerHTML={{
                __html: text?.shopHighlightText,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: text?.shopDescription,
              }}
            />
          </div>
            <div css={[tw`flex flex-col sm:flex-row justify-evenly`]}>
          {shop?.shopPost?.shopHoursOfOperation?.length > 0 &&
            <div css={[tw`w-full`]}>
              <p css={[tw`text-xl`]}>{text?.hoursText}</p>
              <ul css={[]}>
                {shop.shopPost?.shopHoursOfOperation?.map((item, index) => {
                  let hours = `${item.open ? item.open : ""} - ${item.close ? item.close : ""} (${item.day ? item.day : ""})`
                  return (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{ __html: hours }}
                    />
                  )
                })}
              </ul>
            </div>}
            <div css={[tw`w-full mt-4 sm:mt-0`]}>
              <p css={[tw`text-xl`]}>{text?.locationText}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: text?.locationTitle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={[tw`border border-lightGray mt-16`]}>
        <Mapplic
          height="300"
          focusOnLoad={false}
          location={shop.shopPost?.locationMapIdentifier}
          sidebar={false}
          search={false}
        />
      </div>
    </article>
  )
}

export default ShopDetail
