import React from "react"
import tw from "twin.macro"
import { graphql, PageProps } from "gatsby"
import { Shop } from "../dineshop"
import ShopDetail from "../../components/DineAndShop/ShopDetail"
import { SEO } from "../../components/SEO"

const ShopPage: React.FC<PageProps<any>> = ({ data, location }) => {
  const shop: Shop = data.wpShop
  const title = data?.wpShop?.title

  return (
    <>
      <SEO url={location.href} title={title} />
      <section
        css={[
          tw`w-full bg-contain bg-top pt-20 min-h-[40rem]`,
          {
            backgroundImage: "url('/airport-interior.png')",
            backgroundRepeat: "no-repeat",
          },
        ]}
      >
        <ShopDetail shop={shop} />
      </section>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    wpShop(id: { eq: $id }) {
      title
      slug
      id
      nodeType
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      shopPost {
        locationMapIdentifier
        locationTitle
        shopDescription
        shopHighlightText
        shopHoursOfOperation {
          close
          day
          open
        }
        shopThumbnailImage {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default ShopPage
