import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")

interface Props {}

const BackButton: React.FC<Props> = props => {
  const goBackHandler = () => {
    typeof window !== "undefined" && window.history.back()
  }

  const message = "Back"
  const [text, setText] = useState({message})
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({message})
    } else {
      (async () => {
        const textToTranslate = getStringValuesArray([message])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
        
      })()
    }
  }, [language])

  return (
    <button
      css={[tw`mb-12 font-extrabold text-blue-250 hover:text-blue-300 outline-none p-2 -ml-2 self-start hover:underline focus:underline focus:border focus:border-dotted focus:border-blue-250 focus:mt-[-1px]`]}
      onClick={goBackHandler}
    >
      &lt; {text?.message}
    </button>
  )
}

export default BackButton
